<template>
  <div class="news">
    <div class="news-head-wrapper">
      <div class="news-header">
        <Header :appMenuStatusUpdate="appMenuStatusUpdate" />
      </div>
      <div class="news__top">
        <div class="news__heading">News</div>
        <div class="news__type">
          <ul class="list">
            <li
              class="list-items"
              v-on:click="changeKey"
              v-bind:class="{ active: selectedSection === '' }"
              @click="paginatedColum('', 1)"
            >
              ALL
            </li>
            <li
              class="list-items"
              v-on:click="changeKey"
              v-bind:class="{ active: selectedSection === 'ARTICLE' }"
              @click="paginatedColum('ARTICLE', 2)"
            >
              ARTICLE
            </li>
            <li
              class="list-items"
              v-on:click="changeKey"
              v-bind:class="{ active: selectedSection === 'COVERAGE' }"
              @click="paginatedColum('COVERAGE', 3)"
            >
              COVERAGE
            </li>
          </ul>
        </div>
      </div>
      <div class="news__desc">Our stories from news coverages, articles and more</div>
      <div class="news__type__iphone">
        <ul class="list-iphone">
          <li
            class="list-items-iphone"
            v-on:click="changeKey"
            @click="paginatedColum('', 1)"
            v-bind:class="{ 'active-iphone': selectedSection === '' }"
          >
            ALL
          </li>
          <li
            class="list-items-iphone"
            v-on:click="changeKey"
            @click="paginatedColum('ARTICLE', 2)"
            v-bind:class="{ 'active-iphone': selectedSection === 'ARTICLE' }"
          >
            ARTICLE
          </li>
          <li
            class="list-items-iphone"
            v-on:click="changeKey"
            @click="paginatedColum('COVERAGE', 3)"
            v-bind:class="{ 'active-iphone': selectedSection === 'COVERAGE' }"
          >
            COVERAGE
          </li>
        </ul>
      </div>
      <div class="news__card-container">
        <div
          class="child"
          v-for="(data, index) in filteredData"
          v-bind:key="`${reRenderKey}-${index}`"
          @click="goToArticle(filteredData[index].field_news_id)"
        >
          <NewsCard
            :type="data.field_article === 'true' ? 'ARTICLE' : 'COVERAGE'"
            :title="data.title"
            :imgUrl="baseUrl + data.field_news_slide_image"
          />
        </div>
      </div>
      <div class="loader" v-if="loadingStatus">
        <img class="loader--img" src="@/assets/img/search-loader.gif" alt="loader" />
      </div>
      <SubscribeBar :showSusbscribeBar="showSusbscribeBar" :closeSubscribeBar="closeSubscribeBar" />
    </div>
    <div>
      <Footer />
    </div>
  </div>
</template>
<script>
import { BACKEND_BASE_IMG_PATH } from '@/utils/constants.js'
import { Header, NewsCard, Footer, SubscribeBar } from '@/components'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'News',
  components: {
    Footer,
    NewsCard,
    Header,
    SubscribeBar
  },
  data() {
    return {
      baseUrl: BACKEND_BASE_IMG_PATH,
      reRenderKey: 0,
      showSusbscribeBar: true,
      selectedSection: '',
      pagination: {
        current_page: 1,
        code: 1
      },
      loadingStatus: false
    }
  },
  computed: {
    ...mapState('news', ['newsCardData', 'totalPages']),
    filteredData: function() {
      if (this.selectedSection === '') return this.newsCardData
      let filteredArray = this.newsCardData.filter(
        item =>
          (item.field_article === 'true' && this.selectedSection === 'ARTICLE') ||
          (item.field_coverage === 'True' && this.selectedSection === 'COVERAGE')
      )
      return filteredArray.length ? filteredArray : this.newsCardData
    }
  },
  mounted() {
    this.changeNewsCardData()
    this.getNewsCardData(this.pagination)
    document.addEventListener('scroll', this.onScroll, {
      passive: true
    })
  },
  methods: {
    ...mapActions('news', ['getNewsCardData', 'changeNewsCardData']),
    paginatedColum(columnName, columnCode) {
      this.pagination.current_page = 1
      this.changeNewsCardData()
      this.selectedSection = columnName
      this.pagination.code = columnCode
      this.getNewsCardData(this.pagination)
    },
    onScroll() {
      let bottomOfWindow =
        Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) +
          window.innerHeight >=
        document.documentElement.offsetHeight - 10
      if (bottomOfWindow) {
        if (this.totalPages > this.pagination.current_page) {
          this.pagination.current_page += 1
          this.getNewsCardData(this.pagination)
          this.loadingStatus = true
        }
      }
    },
    goToArticle(newsID) {
      this.$router.push({ path: 'article', query: { newsID: newsID } })
    },
    closeSubscribeBar() {
      this.showSusbscribeBar = false
    },
    changeKey() {
      this.reRenderKey = this.reRenderKey + 1
    },
    appMenuStatusUpdate(updatedStatus) {
      this.showSusbscribeBar = updatedStatus
    }
  },
  watch: {
    newsCardData() {
      this.loadingStatus = false
    }
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
@keyframes bottom {
  0% {
    transform: translateY(-900%);
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes top {
  0% {
    transform: translateY(300%);
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes right {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    transform: translatX(0);
    opacity: 1;
  }
}
.news {
  .news-head-wrapper {
    min-height: calc(80vh);
  }
  background-color: $page-background-color;
  .news-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: $primary-white;
    z-index: 10;
  }
}
.news-card {
  @include ipad-10-portrait {
    background-color: $primary-white;
    display: block;
    max-width: 235px;
    max-height: 260px !important;
    border-radius: 10px;
  }
}
.news-card ::v-deep .news-card__image {
  @include ipad-10-portrait {
    height: 135px;
    max-width: 235px !important;
    width: 220px;
    border-radius: 10px 10px 0 0 !important;
  }
}
.news-card ::v-deep .news-card__type-text {
  @include ipad-10-portrait {
    font-size: 13px;
    margin: 9px 0 0 13px;
    line-height: 2.88;
    letter-spacing: 0.2px;
  }
}
.news-card ::v-deep .news-card__title-text {
  @include ipad-10-portrait {
    margin: 0 12.9px 18.7px 11.9px;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
  }
}
.news {
  &__top {
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__heading {
    animation: right 2s;
    width: 20%;
    margin: 50px 0 0 80px;
    object-fit: contain;
    font-family: GothamMedium;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: $primary-black;
    @include ipad-landscape {
      margin: 30px 0 0 80px;
      line-height: 0.88;
      letter-spacing: 0.8;
    }
    @include ipad-10-portrait {
      font-size: 24px;
      line-height: 1.17 !important;
      letter-spacing: 0.6;
      margin: 30px 0 0 50px !important;
    }
    @include ipad-default-portrait {
      margin: 50px 0 0 51px;
    }
    @include iphone-portrait {
      font-size: 24px;
      line-height: 1.42;
      margin: 30px 0 0 15px;
    }
    @include ipad-portrait {
      margin: 30px 0 0 51px;
    }
  }
  &__type {
    float: left;
    width: 50%;
    margin: 50px 80px 0 0;
    @include ipad-landscape {
      margin: 30px 80px 0 0;
    }
    @include ipad-10-portrait {
      margin: 30px 66px 0 0 !important;
      display: unset !important;
    }
    @include iphone-portrait {
      display: none;
    }
    @include ipad-default-portrait {
      margin: 50px 51px 0 0;
    }
    &__iphone {
      display: none;
      @include ipad-10-portrait {
        display: none !important;
      }
      @include iphone-portrait {
        display: unset;
        ul {
          animation: right 2.5s;
          padding: 0;
          list-style: none;
          display: flex;
          justify-content: flex-start;
          margin: 0 0 0 16px;
          @include ipad-portrait {
            margin: 0 0 0 51px;
          }
        }
        .list-items-iphone {
          cursor: pointer;
          margin: 0 30px 0 0;
          object-fit: contain;
          font-family: GothamBook;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.86;
          letter-spacing: 1.4px;
          text-align: left;
          color: $dim-gray;
        }
        .active-iphone {
          color: $blue-active-color;
          font-weight: 500;
        }
      }
    }
    ul {
      animation: bottom 1.8s;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: flex-end;
      margin: 0;
    }
    .list-items {
      cursor: pointer;
      margin: 0 0 0 50px;
      object-fit: contain;
      font-family: GothamBook;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.86;
      letter-spacing: 1.4px;
      text-align: left;
      color: $dim-gray;
    }
    .active {
      color: $blue-active-color;
      font-weight: 500;
    }
  }
  &__desc {
    animation: right 2s;
    object-fit: contain;
    font-family: GothamMedium;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: $light-gray;
    margin: 10px 0 0 80px;
    animation: right 2s;
    @include ipad-10-portrait {
      margin: 10px 0 0 50px !important;
      width: 40% !important;
    }
    @include iphone-portrait {
      margin: 0 0 0 16px;
      width: 92%;
      letter-spacing: 0.29px;
    }
    @include ipad-portrait {
      margin: 0 0 0 51px;
    }
    @include ipad-default-portrait {
      margin: 10px 0 0 51px;
    }
  }
  &__card-container {
    margin: 21px 80px 80px 80px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 24%);
    justify-content: space-between;
    @include iphone-portrait {
      grid-template-columns: repeat(auto-fill, 100%);
      margin: 20px 16px 16px 16px;
    }
    @include ipad-portrait {
      grid-template-columns: repeat(auto-fill, 32%);
      margin: 20px 51px 16px 51px;
    }
    @include ipad-10-portrait {
      grid-template-columns: repeat(auto-fill, 32%);
      margin: 21px 66px 50px 50px !important;
    }
    @include ipad-default-portrait {
      grid-template-columns: repeat(auto-fill, 32%);
      margin: 21px 51px 80px 51px;
    }
    @media (min-width: 897px) and (max-width: 1279px) {
      grid-template-columns: repeat(auto-fill, 32%);
    }
    .child {
      cursor: pointer;
      margin: 0 0 40px 0;
      width: 100%;
      &:nth-child(4n + 1) {
        animation: top 1.8s;
      }
      &:nth-child(4n + 2) {
        animation: top 2s;
      }
      &:nth-child(4n + 3) {
        animation: top 2.2s;
      }
      &:nth-child(4n + 4) {
        animation: top 2.4s;
      }
      @include ipad-landscape {
        margin: 0 15px 30px 0;
        &:nth-child(4n + 1) {
          animation: top 1.8s;
        }
        &:nth-child(4n + 2) {
          animation: top 2s;
        }
        &:nth-child(4n + 3) {
          animation: top 2.2s;
        }
        &:nth-child(4n + 4) {
          animation: top 2.4s;
        }
      }
      @include ipad-10-landscape {
        width: 100%;
        margin: 0 0 30px 0;
        &:nth-child(4n + 1) {
          animation: top 1.8s;
        }
        &:nth-child(4n + 2) {
          animation: top 2s;
        }
        &:nth-child(4n + 3) {
          animation: top 2.2s;
        }
        &:nth-child(4n + 4) {
          animation: top 2.4s;
        }
      }
      @include ipad-10-portrait {
        margin: 0 0 30px 0 !important;
        width: 100% !important;
        &:nth-child(3n + 1) {
          animation: top 2s;
        }
        &:nth-child(3n + 2) {
          animation: top 2.2s;
        }
        &:nth-child(3n + 3) {
          animation: top 2.4s;
        }
      }
      @include iphone-portrait {
        margin: 0 20px 20px 0;
        width: 95%;
        animation: top 2s;
      }
      @include ipad-portrait {
        animation: top 2s;
        width: 100%;
      }
      @include ipad-default-portrait {
        width: 100%;
        margin: 0 0 20px 0;
        &:nth-child(3n + 1) {
          animation: top 2s;
        }
        &:nth-child(3n + 2) {
          animation: top 2.2s;
        }
        &:nth-child(3n + 3) {
          animation: top 2.4s;
        }
      }
    }
  }
}
.loader {
  text-align: center;
  margin-bottom: 100px;
  &--img {
    width: 100px;
  }
}
::v-deep .news-card {
  @include ipad-10-landscape {
    max-width: 100%;
  }
  @include larger-screens {
    min-width: 100%;
    min-height: 400px;
  }
  @include computers {
    max-width: 100%;
  }
  @media (min-width: 896px) and (max-width: 1279px) {
    max-width: 100%;
  }
}
::v-deep .news-card__image {
  @include larger-screens {
    min-height: 250px;
  }
  @include ipad-10-portrait {
    width: 100% !important;
  }
}
</style>
